export default class Timing {
    constructor() {

        if(shared.options.isBot)    return;
        if(!shared.options.log.id)    return;

        this.triesCount = 0;
        this.maxTries = 10;

        this.init();
    }

    init() {
        window.onload = () => {
            setTimeout(() => {
                this.sendTimings();
            }, 50);
        };
    }

    send(data) {
        ++this.triesCount;

        axios.post('/log/timing/', {
            timing: data
        })
            .then((response) => {

            })
            .catch((error) => {
                if (this.triesCount < this.maxTries) {
                    setTimeout(() => {
                        this.send(data);
                    }, 500 * this.triesCount);
                }
            });
    }

    sendTimings() {
        let data = {
            'id':   shared.options.log.id,
            'r':    Math.floor(Math.random() * 10000000),
        };

        let performance = window.performance || window.webkitPerformance || window.msPerformance || window.mozPerformance;

        if (performance && performance.timing) {
            let timing = performance.timing;

            let start = timing.navigationStart;

            for (let key in timing) {
                if (typeof timing[key] != 'number') continue;
                let value = timing[key] + 0;

                if (value != 0) value -= start;

                data[key] = value;
            }
        } else {
            data['navigationStart'] = 12345;
        }

        this.triesCount = 0;

        this.send(data);
    }
}
